export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    logo: require('../../images/logo-coverage.png'),
    title: 'Coverage',
    buttonTitle: 'Coverage',
    usercases: "We develop bank    integrations so you     don't have to",
    description: 'Integrate a single API for all your banks and  payement methods. We handle EBICS, ISO   20022, and banking complexity for you. ',
    imgStart: false,
    img: require('../../images/body-img-video.png'),
    alt: 'Phone',
    dark: true,
    primary: true,
    darkText: false,

};


export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    logo: require('../../images/logo-automation.png'),
    title: 'Automation',
    buttonTitle: 'Automation',
    coverage: 'AUTOMATION',
    usercases: "Build custom end-to-end payement  workflows",
    description: 'Automate payements from initiation  to approval   to reconciliation. Receive real-time notifications as payements are processed  ',
    imgStart: false,
    img: require('../../images/body-img-video2.png'),
    alt: 'Phone',
    dark: true,
    primary: true,
    darkText: false,
};

export const homeObjThree = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    logo: require('../../images/logo-developers.png'),
    title: 'Developers',
    buttonTitle: 'Platform',
    coverage: 'DEVELOPPERS',
    usercases: "A powerful API and  webhooks developpers  will love",
    description: 'Integrate our API and webhooks into your own product and tools. Test in sandbox and launch   in production in days.',
    imgStart: false,
    img: require('../../images/body-img-video3.png'),
    alt: 'Phone',
    dark: false,
    primary: false,
    darkText: true,
};